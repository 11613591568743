@layer utilities {
    /* purgecss start ignore */
    /* == divider-point-up == */
    .divider-point-down{
        position:relative;
        width: 100%;
        height: 3em;
        position: absolute;
        margin-top: 0px;
    }
    .divider-point-down::before{
        content:'';
        font-family:'shape divider from ShapeDividers.com';
        position: absolute;
        bottom: -1px;
        left: -1px;
        right: -1px;
        top: -1px;
        z-index: 3;
        pointer-events: none;
        background-repeat: no-repeat; 
        background-size: 100% 90px;
        background-position: 50% 0%;    
        background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23fbd8c2"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23fbd8c2"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23fbd8c2"/></svg>'); 
    }
        
    @media (min-width:768px){
        .divider-point-down::before{
            background-size: 100% 90px;
            background-position: 50% 0%;   
        }  
    }
        
    @media (min-width:1025px){
        .divider-point-down::before{ 
            bottom: -0.1vw;
            left: -0.1vw;
            right: -0.1vw;
            top: -0.1vw; 
            background-size: 100% 42px;
            background-position: 50% 0%;  
            background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 669.4 133.88"><path fill="%23cfd7cd" d="M0 0c0 133.88 334.7 0 334.7 133.88C334.7 0 669.4 133.88 669.4 0z"/></svg>'); 
        }
    }
    @media (min-width:2100px){
        .divider-point-down::before{
            background-size: 100% calc(2vw + 42px);
        }
    }
    /* purgecss end ignore */
}

@layer components {
    /* purgecss start ignore */
    /* -------------------------------------------------- *
    * Tailwind Grid
    * -------------------------------------------------- */
    .wrapper {
        @apply flex flex-col justify-start v-spaced p-4;
        /* is overflow-hidden needed? */
    }
    .row {
        @apply flex flex-wrap;
    }
    @media (max-width: theme('screens.sm-max.max')) {
        .row {
            @apply flex flex-wrap;
        }
    }
    .container .row,
    .wrapper .row {
        @apply -mx-4;
    }
    /* row styles */
    .row-style--cards {
        @apply -mx-4 px-2 py-4 bg-gray-300;
    }
    .col {
        @apply w-full flex px-4 flex-col;
    }
    /* col styles */
    .col-style--full-width {
        @apply p-4 mx-4;
    }
    /* purgecss end ignore */
}

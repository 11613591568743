/* main.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* user other custom styles */
@import 'blocks/grid.css';
@import 'blocks/animate.css';
@import 'blocks/common.css';
@import 'blocks/home.css';
@import 'blocks/menu.css';
@import 'blocks/dividers.css';

@layer base {
    @media screen and (prefers-reduced-motion: no-preference) {
        html, body {
            scroll-behavior: smooth;
        }
    }
    body {
        @apply text-warmgray-700;
        background-color: #cfd7cd;
    }
    a,
    .separator {
        @apply text-warmgray-600;
    }
    /* purgecss start ignore */
    h1:not([class*="text-lg"]):not([class*="text-xs"]):not([class*="text-sm"]):not([class*="text-base"]):not([class*="text-xl"]) {
        @apply text-4xl;
    }
    h2:not([class*="text-lg"]):not([class*="text-xs"]):not([class*="text-sm"]):not([class*="text-base"]):not([class*="text-xl"]) {
        @apply text-3xl;
    }
    h3:not([class*="text-lg"]):not([class*="text-xs"]):not([class*="text-sm"]):not([class*="text-base"]):not([class*="text-xl"]) {
        @apply text-2xl;
    }
    h4:not([class*="text-lg"]):not([class*="text-xs"]):not([class*="text-sm"]):not([class*="text-base"]):not([class*="text-xl"]) {
        @apply text-xl;
    }
    h5:not([class*="text-lg"]):not([class*="text-xs"]):not([class*="text-sm"]):not([class*="text-base"]):not([class*="text-xl"]) {
        @apply text-lg;
    }
    h6:not([class*="text-lg"]):not([class*="text-xs"]):not([class*="text-sm"]):not([class*="text-base"]):not([class*="text-xl"]) {
        @apply text-base;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply mb-4 text-accent-dark;
        font-family: "Linden Hill", serif;
    }
    .btn {
        transition: background 0.2s 0.1s ease-in-out;
    }
    .outline-0,
    .outline-0:focus {
        outline: none!important;
    }
    [x-cloak] {
        visibility: hidden;
        overflow: hidden;
    }
    body.keyboard-navigation a:focus {
        outline: 2px solid #adbce6;
    }
    .font-custom {
        font-family: "Linden Hill", serif;
    }
    blockquote.pull-right {
        float: right;
        @apply text-xl italic font-semibold text-accent-dark pl-8;
    }
    blockquote.pull-right::before {
        content: "“";
        @apply -ml-4 text-accent-dark;
    }
    blockquote.pull-right::after {
        content: "“" ;
        @apply text-accent-dark;
    }

    .bg-texture {
        background-image: url('/assets/images/bg-texture.jpg');
    }
    .bg-texture-sage {
        background-image: url('/assets/images/bg-texture-sage.jpg');
    }
    .bg-texture-red {
        background-image: url('/assets/images/bg-texture-red.jpg');
    }
    .bg-texture-plum {
        background-image: url('/assets/images/bg-texture-plum.jpg');
    }
    .bg-texture-accent-2-medium {
        background-image: url('/assets/images/bg-texture-accent-2-medium.jpg');
    }
    ul.checkmark li {
        @apply pl-2;
    }

    /* purgecss end ignore */
}

@layer components {
    /* purgecss start ignore */

    /* Content styles */
    /* Goes into new div within .col */
    .content-style--card {
        @apply flex flex-col flex-grow p-4 v-spaced border border-warmgray-200;
    }
    /* Wrap photo in a div with this class to pull it to the edge on left, right, and top. */
    .content-style--edge-photo {
        @apply -mx-4 -mt-4 flex flex-col;
    }
    /* Used to push buttons to the bottom to line up in a row. */
    .content-style--split {
        @apply flex flex-col;
    }
    .content-style--split>*:nth-last-child(2) {
        @apply flex-grow;
    }
    /* -------------------------------------------------- *
    * Styled Objects
    * -------------------------------------------------- */
    .btns {
        @apply flex flex-col justify-center;
    }
    .btn {
        @apply bg-gray-300 inline-block py-4 px-8 rounded-md text-lg;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .15) inset
    }
    .btn-maroon {
        @apply bg-accent-dark text-white;
    }
    .btn-maroon:hover {
        @apply bg-accent-darker text-white;
    }

    .a11ymenu__menu .menu-contact a {
        @apply text-white px-6 ml-4;
    }
    .a11ymenu__menu .menu-contact:before {
        display: none;
    }

    /* purgecss end ignore */
}

@layer utilities {
    /* purgecss start ignore */
    /* Used to only add space between items in an area. It won't add margins to top or under what it is placed on. The sm-max breakpoint is especially helpful for mobile layouts when they collapse into a single column of boxes. */
    .v-spaced>* {
        @apply my-0;
    }
    .v-spaced>*+* {
        @apply mt-4;
    }
    /* You will typically add class="row sm-max:v-spaced" to add space between columns on mobile. */
    @media (max-width: theme('screens.sm-max.max')) {
        .sm-max\:v-spaced>* {
            @apply my-0;
        }
        .sm-max\:v-spaced>*+* {
            @apply mt-4;
        }
    }
    @screen md {
        .md\:v-spaced>* {
            margin-top: 0px;
            margin-bottom: 0px;
        }
        .md\:v-spaced>*+* {
            margin-top: 1rem;
        }
    }
     :root {
        --debug-breakpoint: "xs (<640px)";
    }
    #debug-box {
        content: var(--debug-current-size);
        background: rgb(231, 231, 231);
        border: solid 1px rgb(112, 112, 112);
        font-size: 12px;
        padding: .2em;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 9999999;
    }
    #debug-box::before {
        content: var(--debug-breakpoint);
        background: rgb(231, 231, 231);
        border: solid 1px rgb(112, 112, 112);
        font-size: 12px;
        padding: .2em;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 9999999;
    }
    @screen sm {
        #debug-box:before {
            --debug-breakpoint: "sm (640px)";
        }
    }
    @screen md {
        #debug-box:before {
            --debug-breakpoint: "md (768px)";
        }
    }
    @screen lg {
        #debug-box:before {
            --debug-breakpoint: "lg (1024px)";
        }
    }
    @screen xl {
        #debug-box:before {
            --debug-breakpoint: "xl (1280px)";
        }
    }
    @screen 2xl {
        #debug-box:before {
            --debug-breakpoint: "2xl (1536px)";
        }
    }
    /* purgecss end ignore */
}
/* animate.css */
/* https://github.com/animate-css/animate.css/ */

@layer base {

  :root {
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
  }
}

@layer utilities {

  .animated {
    animation-duration: var(--animate-duration);
    animation-fill-mode: both;
  }

  .animated.infinite {
    animation-iteration-count: infinite;
  }

  .animated.repeat-1 {
    animation-iteration-count: var(--animate-repeat);
  }

  .animated.repeat-2 {
    animation-iteration-count: calc(var(--animate-repeat) * 2);
  }

  .animated.repeat-3 {
    animation-iteration-count: calc(var(--animate-repeat) * 3);
  }

  .animated.delay-1s {
    animation-delay: var(--animate-delay);
  }

  .animated.delay-2s {
    animation-delay: calc(var(--animate-delay) * 2);
  }

  .animated.delay-3s {
    animation-delay: calc(var(--animate-delay) * 3);
  }

  .animated.delay-4s {
    animation-delay: calc(var(--animate-delay) * 4);
  }

  .animated.delay-5s {
    animation-delay: calc(var(--animate-delay) * 5);
  }

  .animated.faster {
    animation-duration: calc(var(--animate-duration) / 2);
  }

  .animated.fast {
    animation-duration: calc(var(--animate-duration) * 0.8);
  }

  .animated.slow {
    animation-duration: calc(var(--animate-duration) * 2);
  }

  .animated.slower {
    animation-duration: calc(var(--animate-duration) * 3);
  }

  @media print, (prefers-reduced-motion: reduce) {
    .animated {
      animation-duration: 1ms !important;
      transition-duration: 1ms !important;
      animation-iteration-count: 1 !important;
    }

    .animated[class*='Out'] {
      opacity: 0;
    }
  }

  /* fadeIn */

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    animation-name: fadeIn;
  }

  /* fadeInUp */

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeInUp {
    animation-name: fadeInUp;
  }
}

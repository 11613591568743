/* home.css */

.column {
  column-count: 1;
  column-gap: 2rem;
  @media (min-width: 640px) {
    column-count: auto;
    column-width: 20rem;
  }
}

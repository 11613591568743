@layer components {
    /* purgecss start ignore */
    /* -------------------------------------------------- *
    * Menu
    * -------------------------------------------------- */
    /* ============ Hamburger button ============ */
    .burger {
      @apply absolute block h-1 w-full left-0 bg-warmgray-700 opacity-100 rounded-md transform rotate-0 transition ease-in-out duration-300;
  }
  .burger-1 {
      @apply top-1 w-full left-0;
  }
  .a11ymenu.mobile--open .burger-1 {
      @apply top-4 w-0 left-1/2;
  }
  .burger-2 {
      @apply top-4;
  }
  .a11ymenu.mobile--open .burger-2 {
      @apply transform rotate-45;
  }
  .burger-3 {
      @apply top-4;
  }
  .a11ymenu.mobile--open .burger-3 {
      @apply transform -rotate-45;
  }
  .burger-4 {
      @apply top-7 w-full left-0;
  }
  .a11ymenu.mobile--open .burger-4 {
      @apply top-4 w-0 left-1/2;
  }
  /* ============ Menu Functionality ============ */
  .a11ymenu__menu,
  .a11ymenu__menu ul,
  .a11ymenu__menu li {
      @apply flex flex-col relative list-none p-0 border-0;
  }
  .a11ymenu__menu .has-submenu {
      @apply flex-row;
  }
  /* Overflow: hidden stops the flash of submenus when loading */
  .a11ymenu__menu {
      @apply overflow-hidden;
  }
  .a11ymenu__menu:hover,
  .a11ymenu__menu:focus-within {
      @apply overflow-visible;
  }
  /* Place link and button side-by-side */
  .a11ymenu__menu .has-submenu {
      @apply flex flex-row justify-between flex-wrap items-center flex-row-reverse;
      /* Remove flex-row-reverse to place open/close icon on the right. */
  }
  .a11ymenu__menu .has-submenu>a,
  .a11ymenu__menu .has-submenu>.separator {
      @apply pl-1;
  }
  .a11ymenu__menu .has-submenu>.separator {
      @apply cursor-pointer;
  }
  /* No reason to show outline when using a mouse */
  body:not(.keyboard-navigation) .a11ymenu__menu a,
  body:not(.keyboard-navigation) .a11ymenu__menu .separator,
  body:not(.keyboard-navigation) .a11ymenu__menu button {
      outline: none;
  }
  /* All submenu containers. Must have visibility: hidden to tab across top bar with menus closed. */
  .a11ymenu__menu .has-submenu ul {
      @apply invisible;
      flex-basis: 100%;
  }
  .a11ymenu__menu a,
  .a11ymenu__menu .separator {
      @apply flex flex-grow flex-wrap items-center px-4 py-2 no-underline leading-5 text-warmgray-700 text-left;
  }
  /* All buttons */
  .a11ymenu__menu .has-submenu>button {
      @apply flex justify-center items-center leading-none;
  }
  /* All submenu items */
  .a11ymenu__menu button[aria-expanded="true"]+ul {
      @apply visible;
  }
  .a11ymenu__menu button[aria-expanded="false"]+ul {
      @apply invisible;
  }
  /* ---------- Mobile Only ---------- */
  @media (max-width: theme('screens.sm-max.max')) {
      .a11ymenu__menu {
          @apply w-full h-0;
      }
      .a11ymenu.mobile--closed .a11ymenu__menu,
      .a11ymenu__menu .has-submenu>button[aria-expanded="false"]+ul {
          @apply overflow-hidden;
          max-height: 0;
      }
      .a11ymenu.mobile--open .a11ymenu__menu,
      .a11ymenu__menu .has-submenu>button[aria-expanded="open"]+ul {
          max-height: 2000px;
          @apply h-auto;
      }
  }
  @screen md {
      .a11ymenu__menu {
          @apply flex-row;
      }
      .a11ymenu__menu ul {
          @apply absolute;
      }
      .a11ymenu__menu>li>a,
      .a11ymenu__menu>li>.separator {
          @apply justify-center;
      }
      .a11ymenu__menu>li>a+ul,
      .a11ymenu__menu>li>.separator+ul,
      .a11ymenu__menu>li>a+button+ul,
      .a11ymenu__menu>li>.separator+button+ul {
          @apply top-full;
      }
      .a11ymenu__menu ul a+ul,
      .a11ymenu__menu ul .separator+ul,
      .a11ymenu__menu ul a+button+ul,
      .a11ymenu__menu ul .separator+button+ul {
          @apply left-full;
      }
      .a11ymenu__menu>li:last-child a+ul,
      .a11ymenu__menu>li:last-child .separator+ul,
      .a11ymenu__menu>li:last-child a+button+ul,
      .a11ymenu__menu>li:last-child .separator+button+ul {
          right: 0;
      }
      .a11ymenu__menu>li:last-child ul a+ul,
      .a11ymenu__menu>li:last-child ul .separator+ul,
      .a11ymenu__menu>li:last-child ul a+button+ul,
      .a11ymenu__menu>li:last-child ul .separator+button+ul {
          left: calc(-100% - 2px);
          width: 100%;
      }
  }
  /* ============ Menu Styles ============ */
  .a11ymenu h1 {
      @apply text-primary-dark;
  }
  .a11ymenu--subtext {
      @apply flex text-sm;
      flex-basis: 100%;
  }
  /* Submenus */
  .a11ymenu__menu ul {
      @apply bg-warmgray-300 border border-warmgray-500 border-solid ease-in-out origin-top shadow-md transition-all delay-150 duration-300 ease-out scale-y-0;
      min-width: 12.5rem;
  }
  .a11ymenu__menu ul>li {
      @apply border-t border-warmgray-500 border-solid;
  }
  .a11ymenu__menu ul>li:first-child {
      @apply border-t-0;
  }
  .a11ymenu__menu ul>li:hover,
  .a11ymenu__menu ul>li:focus-within {
      @apply bg-warmgray-100;
  }
  /* Open and close submenu with a click */
  .a11ymenu__menu .has-submenu>button[aria-expanded="true"]+ul {
      @apply transform scale-y-100;
  }
  .a11ymenu__menu .has-submenu>button[aria-expanded="false"]+ul {
      @apply transform scale-y-0;
  }
  .a11ymenu__menu .has-submenu>button {
      @apply m-1 rounded border-solid border border-warmgray-400;
  }
  /* Expand/collapse icon */
  .a11ymenu__menu .has-submenu button>span {
      @apply p-1;
  }
  .no-js .a11ymenu__menu .has-submenu::after {
      @apply flex justify-center items-center h-6 w-6;
  }
  /*.no-js .a11ymenu__menu .has-submenu .has-submenu {
      @apply relative flex w-full h-full flex-row justify-center items-center;
  }*/
  .a11ymenu__menu .has-submenu button>span::before,
  .no-js .a11ymenu__menu .has-submenu::after {
      @apply origin-center transition duration-300 ease-out text-xl;
      content: "+";
      line-height: .1;
      /* Use origin-top if using arrows */
  }
  .a11ymenu__menu li>button>span,
  .no-js .a11ymenu__menu .has-submenu::after {
      @apply origin-center transition duration-300 ease-out;
  }
  .a11ymenu__menu li>button[aria-expanded="true"]>span,
  .no-js .a11ymenu__menu li:hover::after {
      transform: rotate(45deg) translate(0, 0%);
  }
  .a11ymenu__menu li>button[aria-expanded="false"]>span,
  .no-js .a11ymenu__menu li::after {
      transform: rotate(0deg) translate(0, 0%);
  }
  /* Create space for +/- where button is and adjust if javascript is turned off. */
  .no-js .a11ymenu__menu .has-submenu:after {
      @apply -left-4;
  }
  .no-js .a11ymenu__menu .has-submenu .has-submenu:after {
      @apply left-4;
  }
  /* Code for using an arrow instead of + / - */
  /*
  .a11ymenu__menu .has-submenu button>span:after,
  .no-js .a11ymenu__menu .has-submenu:after {
      @apply absolute top-1/2 w-2 h-2 border-t border-l border-solid border-current origin-top transition duration-500 ease-out;
      content: "";
  }
  .a11ymenu__menu li>button[aria-expanded="true"]>span:after,
  .a11ymenu__menu li:hover>button>span:after,
  .no-js .a11ymenu__menu li:hover:after {
      transform: rotate(225deg) translate(0, -50%);
  }
  .a11ymenu__menu li>button[aria-expanded="false"]>span:after,
  .no-js .a11ymenu__menu li:after {
      transform: rotate(135deg) translate(22%, -35%);
      /* Use 135deg and -45deg for left facing arrow and use translate(0, -50%) for both. *
  }
  /* Create space for arrow where button is and adjust arrow if javascript is turned off. *
  .no-js .a11ymenu__menu .has-submenu {
      @apply mr-6;
  }
  .no-js .a11ymenu__menu .has-submenu:after {
      @apply -right-4;
  }
  .no-js .a11ymenu__menu .has-submenu .has-submenu:after {
      @apply right-4;
  }
  */
  @media (max-width: theme('screens.sm-max.max')) {
      .a11ymenu__menu {
          @apply border border-b-0 border-gray-500 -mb-4 mt-4;
      }
      .a11ymenu__menu .has-submenu button {
          @apply bg-warmgray-200;
      }
      /* Set up animations for container and buttons */
      .a11ymenu__menu,
      .a11ymenu__menu .has-submenu>button+ul {
          @apply transition-all duration-300 ease-out origin-top;
      }
      /* Main level */
      .a11ymenu__menu>li {
          @apply border-0 border-solid border-b border-warmgray-500;
      }
      /* Submenus */
      .a11ymenu__menu ul {
          @apply border-0 border-t border-warmgray-500 border-solid;
      }
      .a11ymenu.mobile--closed .a11ymenu__menu {
          @apply transform scale-y-0;
      }
      .a11ymenu.mobile--open .a11ymenu__menu {
          @apply transform scale-y-100;
      }
      /* Add prefix symbols to submenus to show level. Add more if menu is deeper than 2 levels. */
      .a11ymenu.mobile--closed .a11ymenu__menu,
      .a11ymenu__menu .has-submenu>button[aria-expanded="false"]+ul {}
      .a11ymenu__menu ul>li>a::before,
      .a11ymenu__menu ul>li>span::before {
          @apply inline-block mr-2;
          content: "- ";
      }
      .a11ymenu__menu ul ul>li>a::before,
      .a11ymenu__menu ul ul>li>span::before {
          @apply inline-block mr-2;
          content: "- - ";
      }
  }
  @screen md {
      .a11ymenu--subtext {
          @apply justify-center;
      }
      .a11ymenu__menu>li>a,
      .a11ymenu__menu>li>.separator {
          @apply text-center justify-center;
      }
      /* Hover underline on top level */
      .a11ymenu__menu>li:hover:before,
      .a11ymenu__menu>li:focus-within:before {
          @apply absolute bottom-0 w-full h-0.5 bg-gray-400;
          content: "";
      }
      .a11ymenu__menu .has-submenu>button,
      .no-js .a11ymenu__menu .has-submenu::before {
          @apply border-warmgray-300;
          /* Add h-1/2 if using arrows */
      }
  }
  /* Add support for hover to work if js is turned off */
  .no-js .a11ymenu__menu li:hover>ul {
      @apply visible transform scale-y-100;
  }
  /* Uncomment to use hover instead of click */
  /*
  .a11ymenu__menu li:hover>ul {
      @apply transform scale-y-100;
  }
  .a11ymenu__menu .has-submenu:hover>ul,
  .a11ymenu__menu .has-submenu:hover>button+ul {
      @apply visible;
  }
  .a11ymenu__menu .has-submenu:hover>button[aria-expanded="false"]+ul {
      @apply transform scale-y-100;
  }
  .a11ymenu__menu li:hover>button[aria-expanded="false"]>span {
      transform: rotate(45deg) translate(0, 0%);
  }
  */
  /* purgecss end ignore */
}